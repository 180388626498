.nav {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: var(--bg);
    height: var(--nav-size);
    z-index: 999;
}

.navbar {
    display: flex;
    height: var(--nav-size);
    justify-content: space-between;
    align-items: center;
}

.navbar__logo {
    height: calc(var(--nav-size) * .8);
}

.nav__btn {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 3rem;
    height: 2.9rem;
    border: 0;
    background: transparent;
    gap: .65rem;
}

.nav__btn>div {
    background-color: #ffffff;
    height: 2px;
    width: 100%;
    border-radius: 5px;
    transition: all var(--speed);
    transform-origin: left;
}

.activeToggle div:first-child {
    transform: rotate(45deg);
    margin-left: .3rem;
    margin-top: .15rem;
}

.activeToggle div:nth-child(2) {
    opacity: 0;
}

.activeToggle div:last-child {
    transform: rotate(-45deg);
    margin-left: .3rem;
    margin-bottom: .15rem;
}

.nav__menu {
    position: absolute;
    background-color: var(--bg);
    width: 100%;
    max-height: 0;
    top: 84px;
    right: 0;
    overflow: hidden;
    border-radius: var(--border-radius);
    transform: scaleY(1);
    transition: all var(--speed);
}

.nav__menu-show {
    transform: scaleY(1);
    max-height: 100vh;
    transform-origin: top;
    transition: all .2;
}

.nav__list {
    padding: 1rem;
}

.nav__divider {
    margin: .5rem;
}

.nav__link {
    display: block;
    width: 100%;
    font-size: rem;
    font-weight: bold;
}

.nav__link:hover {
    color: var(--title-alt);
    transition: all var(--speed);
}

@media screen and (min-width: 1200px) {

    .nav__toggle {
        display: none;
    }

    .nav__menu {
        position: initial;
    }

    .nav__list {
        display: flex;
        padding: 0;
        align-items: center;
    }

    .nav__list>li:nth-child(9) {
        margin-left: 5rem;
    }

    .nav__link {
        padding: 0 .4rem;
        font-size: 20px;
    }

    .nav__divider {
        display: none;
    }

    .nav__item-social {
        padding: 0;
    }

    .spacespan {
        margin-left: 1rem;
    }
}

/* .nav {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: var(--bg);
    height: var(--nav-size);
    z-index: 10;
}

.navbar {
    display: flex;
    height: var(--nav-size);
    justify-content: space-between;
    align-items: center;
}

.navbar__logo {
    height: calc(var(--nav-size) * .8);
}

.nav__btn {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 3rem;
    height: 2.9rem;
    border: 0;
    background: transparent;
    gap: .65rem;
}

.nav__btn>div {
    background-color: #ffffff;
    height: 2px;
    width: 100%;
    border-radius: 5px;
    transition: all var(--speed);
    transform-origin: left;
}

.activeToggle div:first-child {
    transform: rotate(45deg);
    margin-left: .3rem;
    margin-top: .15rem;
}

.activeToggle div:nth-child(2) {
    opacity: 0;
}

.activeToggle div:last-child {
    transform: rotate(-45deg);
    margin-left: .3rem;
    margin-bottom: .15rem;
}

.nav__menu {
    position: absolute;
    display: flex;
    justify-content: space-between;
    background-color: var(--bg);
    max-height: 0;
    top: 84px;
    right: 0;
    overflow: hidden;
    border-radius: var(--border-radius);
    transform: scaleY(1);
    transition: all var(--speed);
}

.nav__menu-show {
    transform: scaleY(1);
    max-height: 100vh;
    transform-origin: top;
    transition: all .2;
}

.nav__list {
    padding: 1rem;
}

.nav__divider {
    margin: .5rem;
}

.nav__link {
    display: block;
    width: 100%;
    font-weight: bold;
}

.nav__link:hover {
    color: var(--title-alt);
    transition: all var(--speed);
}

@media screen and (min-width: 1200px) {

    .nav__toggle {
        display: none;
    }

    .nav__menu {
        position: initial;
        width: 90%;
        justify-content: space-evenly;
    }

    .nav__list {
        display: flex;
        width: 80%;
        padding: 0;
        align-items: center;
    }

    .nav__list>li:nth-child(10) {
        margin-left: 1rem;
    }

    .nav__link {
        padding: 0 .5rem;
        font-size: var(--font_size_navbar);
    }

    .nav__socialLinks {
        display: flex;
    }

    .nav__divider {
        display: none;
    }

    .nav__item-social {
        padding: 0;
        margin-right: .5rem;
    }

    .spacespan {
        margin-left: 1rem;
    }
} */