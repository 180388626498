* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family:'Lato', 'Open Sans';
  /*line-height: 1.3;
  letter-spacing: .7px;*/
}

:root {
  --bg: #004694;
  --text-color: #FFF;
  --text-color-alt: #004694;
  --font_size_navbar: 20px;
  --bg-green: #a8cf45;
  --bg-alt: #cbdeff;
  --title: #2957a4;
  --title-alt: #89a7da;
  --bg-light: rgb(230, 231, 240);
  --bg-accent: #484a4d;
  --nav-size: 94px;
  --border: 1px solid #2957a4;
  --border-radius: .5rem;
  --speed: 200ms;
  --speed-slow: 300ms;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  scroll-behavior: smooth;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: var(--text-color);
}

img {
  max-width: 100%;
  height: auto;
}

.spacebox {
  height: var(--nav-size);
}

.title {
  text-align: center;
  color: #2957a4;
  font-size: 60px;
  font-weight: 600;
  margin: 1em 0;
}

.subtitle {
  color: #2957a4;
  padding: 1em 0;
}

.divider {
  display: block;
  /* background-image: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.2), transparent); */
  background-image: linear-gradient(to right, var(--text-color-alt), var(--text-color-alt), var(--text-color-alt));
  border: 0;
  height: 2px;
}

.container {
  margin: 0 1.5rem;
}

.btn {
  display: inline-block;
  font-size: 1rem;
  padding: .6rem 1.2rem;
  background-color: var(--bg);
  color: var(--text-color);
  border-radius: var(--border-radius);
  margin: .7rem;
  margin-left: 0;
  font-weight: 600;
  border: 0;
}

.btn-alt {
  background-color: #FFF;
  color: var(--bg);
}

.btn:hover {
  color: var(--title-alt);
  cursor: pointer;
  transition: color var(--speed-slow);
}

.btn-active {
  color: var(--title-alt);
}

.btn__close {
  align-self: end;
  margin-right: .5rem;
  border: 0;
}

.btn__unselected {
  background-color: var(--bg-alt);
  color: #000;
}

.btn-ws {
  background-color: #68f19b;
}

.svgIcon {
  display: inline;
  margin-right: .5rem;
  height: 2rem;
  /* filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(269deg) brightness(103%) contrast(103%); */
}

.svgIcon:hover {
  filter: invert(0%) sepia(16%) saturate(10%) hue-rotate(0deg) brightness(95%) contrast(0%);;
  transition: all var(--speed);
}

.boxShadow {
  box-shadow: 10px 10px 34px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 10px 10px 34px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 10px 10px 34px 0px rgba(0, 0, 0, 0.2);
}

.border-rounded {
  border-radius: var(--border-radius);
}

.generic-margin {
  margin: 3rem 0;
}

.small-margin {
  margin: .5rem;
}

.loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid var(--bg);
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

.marginVideo{
  margin: 2rem 0;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 1200px) {

  .container {
    margin: 0 auto;
    max-width: 1210px;
  }

}