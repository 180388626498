.sucursalesDetails__container {
    margin-top: 2rem;
}

.sucursalesDetails__box-content {
    margin-bottom: 3rem;
}

.sucursalEmbedMap {
    text-align: center;
}


@media screen and (min-width: 650px) {

    .sucursalesDetails__container {
        display: flex;
        justify-content: space-evenly;
        flex: 1;
    }

    .sucursalEmbedMap {
        width: 50%;
    }

    .sucursalesDetails__box {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .sucursalesDetails__box-content {
        display: flex;
        flex-direction: column;
    }

}