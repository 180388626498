.page404 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 85vh;
}

.page404 a {
    width: max-content;
}

.text404 {
    max-height: 20vh;
    overflow: hidden;
    font-weight: bold;
    font-size: 8px;
    line-height: 1;
    color: transparent;
    background-image: url('images/bigLogo.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-clip: text;
}

.title404 {
    font-size: 50px;
    text-align: center;
    color: var(--title)
}

.disclaimer404 {
    color: var(--title-alt);
    height: max-content;
    text-align: center;
}

@media screen and (min-width: 1000px) {
    .text404 {
        max-height: calc(100%);
    }
}